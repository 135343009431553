
#PageHomeAboutLHC {
    background-color: var(--light-primary-color);
}

#PageHomeAboutLHC .col-md-3:has(img) {
    text-align: center;
}

#PageHomeAboutLHC img {
    width: 100%;
    margin-top: 40px;
}

#PageHomeAboutLHC .vis-network {
    border: #FFF 3px solid;
}