
#PageHomeServices .PageHomeServices-content {
    min-height: 300px;
    padding: 20px 30px;
    background-color: var(--light-primary-color);
}

#PageHomeServices .PageHomeServices-menu {
    padding: 5px 10px;
    border-top: solid 1px black;
}

#PageHomeServices  .PageHomeServices-service-link:hover {
    text-decoration: none;
}

#PageHomeServices .PageHomeServices-service-desc-abstract-wrap {
    display: flex;
    background-color: rgba(0, 0, 0, .7);
    position: relative;
    color: white;
    width: 100%;
    height: 150px;
    margin: 200px 0px 0px 0px;
    padding: 10px 20px;
    z-index: 100;
}

#PageHomeServices .PageHomeServices-service-desc-abstract {
    position: relative;
    width: 100%;
    height: calc(100% - 55px);
    text-overflow: ellipsis;
    white-space: initial;
    overflow: hidden;
}

#PageHomeServices .PageHomeServices-service-desc-abstract-wrap button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 1;
}

#PageHomeServices .PageHomeServices-service-desc-image {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    height: 180px;
    z-index: 99;
}

#PageHomeServices .PageHomeServices-service-desc-image img,
#PageHomeServices .PageHomeServices-service-desc-image .NoImage {
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}