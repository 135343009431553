
.SearchField {
	position: relative;
}

.SearchField .small-button {
	position: absolute;
	right: 2px;
	top: 7px;
	background-color: transparent;
	color: var(--primary-color);
}

.SearchField .small-button i {
	padding-top: 4px !important;
}

.SearchField input  {
	padding-right: 28px;
}