
#PageContact .PageContact-content {
    background-color: var(--light-primary-color);
    margin-bottom: 150px;
    padding: 60px 0px 20px 0px;
}

#PageContact .PageContact-content .col-md-3:has(img) {
    text-align: center;
}

#PageContact .PageContact-content img {
    width: 100%;
    vertical-align: middle;
}