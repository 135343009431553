
@media (min-width: 0px) {
 	#PageHomeEvents .PageHomeEvents-image {
        position:  relative;
        width: 100%;
        height: 250px;
        background: repeating-linear-gradient(
          135deg,
          rgba(0, 159, 227, 0.1),
          rgba(0, 159, 227, 0.1) 10px,
          rgba(0, 0, 0, 0) 10px,
          rgba(0, 0, 0, 0) 20px
        ) !important;
    }
}

@media (min-width: 500px) {
    #PageHomeEvents .PageHomeEvents-image {
    	height: 250px;
        object-fit: cover;
        display: flex;
        margin: auto;
        flex: 0 0 220px;
        overflow: hidden;
    }
}

#PageHomeEvents .PageHomeEvents-image img,
#PageHomeEvents .PageHomeEvents-image .NoImage {
	position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: unset !important;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}

#PageHomeEvents .NoImage {
    background: repeating-linear-gradient(
      135deg,
      rgba(0, 159, 227, 0.1),
      rgba(0, 159, 227, 0.1) 10px,
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0) 20px
    ) !important;
}

#PageHomeEvents .NoImage-logo i {
    color: rgba(0, 159, 227, 0.1) !important;
}