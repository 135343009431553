@font-face { font-family: "FjallaOne"; src: url('./font/FjallaOne-Regular.ttf'); }

:root {
    --default-color: grey;
    --default-light-color: lightgrey;

    --primary-color: #009fe3;
    --light-primary-color: #bcebff;
    --hovered-primary-color: #8fddff;

    --secondary-color: #e40613;
    --light-secondary-color: #fed7da;
    --hovered-secondary-color: #ffa8b0;

    --default-font-color: #58595b;
    --title-font-color: #010101;

    --default-font: 'Roboto', Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    --title-font: "FjallaOne", "Helvetica Neue", Heflvetica, Arial, sans-serif;

    --media-transition-width: 768px;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: grey;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    color: var(--default-font-color);
    font-family: var(--default-font);
}

#App {
    width: 100%;
    height: 100%;
}

h1 {
	color: var(--title-font-color);
    text-transform: uppercase;
    font-family: var(--title-font);
    font-weight: normal;
}

h2 {
	color: var(--title-font-color);
    font-family: var(--title-font);
    font-weight: normal;
}

h3 {
	color: var(--title-font-color);
    font-family: var(--title-font);
    font-weight: normal;
    margin: 15px 0px;
    font-size: 22px;
}

h4 {
	color: var(--title-font-color);
    font-family: var(--title-font);
    font-weight: normal;
    padding: 0px 10px;
    margin: 15px 0px;
    font-size: 16px;
}

a {
	color: var(--primary-color);
}

a:hover {
	color: var(--secondary-color);
}

blockquote {
	position: relative;
	margin: 30px 20px 40px 20px;
	padding: 30px 20px;
	font-style: italic;
	border-top: solid 1px;
    border-bottom: solid 1px;
}

blockquote:after {
    position: absolute;
    content: "”";
    color: grey;
    font-size: 5rem;
    line-height: 0;
    bottom: -15px;
    right: 30px;
}

.showFulltext {
	white-space: initial !important;
    overflow: visible !important;
    text-overflow: unset !important;
}

.clickable {
	cursor: pointer;
}

.clickable:hover {
	background-color: rgba(0, 159, 227, 0.05);
}

/* COLORS */

.blue-font {
	color: var(--primary-color) !important;
}

.red-font {
	color: var(--secondary-color) !important;
}

.red-background {
	background-color: var(--secondary-color) !important;
}

.grey-background {
	background-color: grey !important;
}

/* PAGE */

.page {
	padding: 10px 20px;
}

.row {
	padding: 10px 0px;
}

.row-spaced {
	padding-bottom: 60px ;
}

.full-page {
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.full-size {
	height: 100%;
	width: 100%;
}

.max-sized-page {
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display: none;
    }
}

.centered {
	text-align: center;
}

.middled {
	display: flex;
	align-items: center;
	justify-content: center;
  	height: 100%;
}

.justified {
	text-align: justify;  
}

p {
	text-align: justify;  
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}