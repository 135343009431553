
#PageEvents .CheckBox {
	display: inline-block;
	width: auto;
	margin: 0px 3px 0px 8px;
	padding-right: 20px;
	padding-left: 20px;
}

#PageEvents .PageEvents-right-filter {
	float: right;
}