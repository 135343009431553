
.Banner, .Banner .carousel-root, .Banner .carousel {
    position: relative;
    width: 100%;
}

.Banner > div {
    height: 100%;
    position: relative;
}

.Banner img {
    height: 100% !important;
    width: 100%;
    object-fit: cover !important;
}
