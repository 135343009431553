
#PageHomeCatch {
    margin: 80px 0px 60px 0px;
    font-size: 30px;
    text-align: center;
}

#PageHomeCatch .PageHomeCatch-catch {
    position: relative;
    width: fit-content;
    margin: auto;
    padding: 30px 40px;
    border: #DDD 5px solid;
}

#PageHomeCatch .PageHomeCatch-quote:after {
    top: 30px;
    width: 100%;
    color: grey;
    content: "”";
    font-size: 10rem;
    line-height: 0;
    position: absolute;
    left: 10px;
    text-align: left;
}